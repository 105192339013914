<template>
  <f7-navbar class="explore-nav">
    <f7-nav-left>
      <LeftPanelNavigationComponent />
    </f7-nav-left>

    <f7-nav-title>
      <a href="/home/explorepjf/">
        <img class="navlogo" src="@/assets/images/logo/logo-name-color.svg" alt="Logo" loading="lazy" />
      </a>
      <ul class="nav-links">
        <li :class="{ active: activeLink == 'explorePJFPage' }" @click="setType()">
          <a href="/home/explorepjf/">
            <span v-if="type == 'ALL'">{{ $t.getTranslation('LBL_ALL_CONTENT') }} <font-awesome-icon :icon="['fas', 'chevron-down']" fixed-width class="clear-margin" /> </span>
            <span v-else
              >{{ $t.getTranslation('LBL_PJF_CONTENT') }}
              <font-awesome-icon :icon="['fas', 'chevron-up']" fixed-width class="clear-margin" />
            </span>
          </a>
        </li>
        <!-- <li :class="{ active: activeLink == 'videoPage' }">
          <a href="/home/video/"> {{ $t.getTranslation("LBL_VIDEO") }}</a>
        </li>
        <li :class="{ active: activeLink == 'articlePage' }">
          <a href="/home/article/"> {{ $t.getTranslation("LBL_ARTICLE") }}</a>
        </li> -->
        <!-- <li :class="{ active: activeLink == 'weeklyRecommend' }">
          <a href="/home/weekly/">
            {{ $t.getTranslation("LBL_WEEKLY_PICKS") }}
          </a>
        </li> -->

        <li :class="{ active: activeLink == 'recordPage' }">
          <a href="/post/create/?mode=tastingrecord">
            {{ $t.getTranslation('LBL_SCAN') }}
          </a>
        </li>
      </ul>
    </f7-nav-title>
    <f7-nav-right>
      <f7-link class="searchbar-enable" :data-searchbar="'.' + search">
        <font-awesome-icon :icon="['far', 'search']" fixed-width />
      </f7-link>
    </f7-nav-right>
    <f7-searchbar v-model:value="searchValue" :class="search" expandable :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')" :clear-button="true"></f7-searchbar>
  </f7-navbar>
</template>

<style scoped>
.nav-links li a {
  font-weight: 300;
  color: #222;
}
.clear-margin {
  margin-left: 0px;
}
ul.nav-links li {
  padding: 0 10px;
}
ul.nav-links {
  display: flex;
  justify-content: flex-end;
}
</style>

<script>
import { defineComponent, computed, ref, watch, defineAsyncComponent } from 'vue'
import { useStore } from '@/store';

// import LeftPanelNavigationComponent from '@/components/navigations/LeftPanelNavigationComponent.vue'

export default defineComponent({
  name: 'HomeNavigationComponent',
  components: {
    LeftPanelNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "left-panel" */ /* webpackMode: "lazy" */ '@/components/navigations/LeftPanelNavigationComponent.vue'))
  },
  props: {
    search: String
  },
  emits: ['searched', 'changeType'],
  setup(props, { emit }) {
    const store = useStore();

    const activeLink = computed(() => store.getters['page/getActiveLink'])
    const searchValue = ref('')
    const type = ref('ALL')
    let searchTimeout = false

    const setType = () => {
      if (type.value == 'ALL') {
        type.value = 'PJF'
      } else {
        type.value = 'ALL'
      }
      emit('changeType', type.value)
    }

    watch(searchValue, (newValue, oldValue) => {
      if (searchTimeout) clearTimeout(searchTimeout)

      searchTimeout = setTimeout(function() {
        if (newValue == null) {
          return true
        }
        emit('searched', searchValue.value)
      }, 800)
    })

    return {
      activeLink,
      searchValue,
      type,
      setType,
      store
    }
  }
})
</script>
